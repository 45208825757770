.csr-ptr {
  cursor: pointer !important;
}

.csr-not {
  cursor: not-allowed !important;
}

.csr-def {
  cursor: default !important;
}

.spn-styl {
  width: 3rem;
  height: 3rem;
  color: #06c1cf;
}

.modal-style {
  display: block;
  background-color: rgba(97, 115, 146, 0.83);
}

.ful-wid {
  width: 100%;
}

.ali-cntr {
  text-align: center !important;
}

.ali-rig {
  text-align: right !important;
}

.wr-clr {
  background-color: rgb(241, 158, 63);
}

.tootltip {
  min-width: auto !important;
  max-width: 500px !important;
  height: auto !important;
}

.ft-st {
  font: 500 12px/15px "Roboto", sans-serif !important;
  color: #617392 !important;
}

.fm-dt-st {
  font: 500 15px/18px "Roboto", sans-serif !important;
  color: #617392 !important;
}

.mn-dv {
  text-align: left !important;
}

.st-dt {
  padding-left: 120px !important;
}

.ed-dt {
  padding-left: 95px !important;
}

.text-right {
  text-align: right;
}

.txt-sze {
  font-size: small;
}

.err-msg {
  color: #fb0000;
}

.date-flex {
  display: flex;
  align-items: center;
}

.hd-bg-clr {
  background-color: #e7f7fc !important;
}

.main-hdr {
  font: 700 17px/23px "Roboto", sans-serif !important;
  padding: 7px;
}

.date-range {
  color: #617392;
  font: 500 13px/13px "Roboto", sans-serif;
}

.common-widget {
  width: 400px;
  height: 240px;
}

.otr-custom {
  margin-top: -10px;
}

.otr-custom i {
  color: #677ca2;
}

.otr-custom .selected {
  color: #1cc6d3;
}

/* Date Picker Stylesheet */

.datePicker {
  /* width: 9rem !important; */
  height: 2.4rem !important;
  border: 2px solid #e8f0ff !important;
  font: 500 12px/15px "Roboto", sans-serif !important;
}

.rightMargin {
  margin-right: 8px !important;
}

.leftMargin {
  margin-left: 8px !important;
}

.ant-picker-input > input::placeholder {
  color: #757575 !important;
  font: 500 12px/15px "Roboto", sans-serif !important;
}

.ant-picker-input > input {
  color: #757575 !important;
  font: 500 12px/15px "Roboto", sans-serif !important;
}

.search-otr {
  margin-right: 8px !important;
  background-color: white !important;
}

.product-alert .modal-width-large {
  max-width: 1200px !important;
}

.title-length {
  max-width: 20rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deals-title-length {
  max-width: 15rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deals-name-length {
  max-width: 10rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-selected {
  background-color: #f2f6fe;
}

.profile-drop-margin {
  margin-left: 20px !important;
}

.profile-margin-right {
  margin-right: 9.5px !important;
}

.table-button-size {
  width: 32px;
}

/*change Number Modal Stylesheet*/

.otpBtn {
  width: 8rem !important;
}

#otp-section-input {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    rgb(68, 68, 68) 70%,
    rgba(230, 230, 230, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 287px;
  min-width: 350px;
}

.change-phone-modal .otp-modal {
  height: auto !important;
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 377px) {
  #otp-section-input {
    padding-left: 18px !important;
    letter-spacing: 30px !important;
    background-size: 38px 1px !important;
    background-position-x: 35px !important;
  }
  #otp-section-outer {
    width: 226px !important;
    overflow: hidden !important;
    margin: auto !important;
  }
}

#otp-section-inner {
  left: 0 !important;
  position: sticky !important;
}

#otp-section-outer {
  width: 286px;
  overflow: hidden !important;
  margin: auto !important;
}

.change-phone-modal {
  /*Change Phone number input field Height*/
  min-height: auto !important;
}

.change-phone-modal .enter-otp-form {
  height: auto !important;
  font-size: 1rem !important;
}

.change-number-section .react-tel-input .form-control {
  /*Change Phone number input field Width*/
  width: 0 !important;
  border: none !important;
}

.change-number-section .react-tel-input .search-box {
  /*countrysearch*/
  width: 97% !important;
  margin: 0 !important;
}

.change-number-section {
  display: flex;
}

.change-number-section .number-input-section {
  width: 100% !important;
  z-index: 999 !important;
  display: flex !important;
  border-radius: 7px !important;
  border: 1px solid #e1e1e1 !important;
}

.change-number-section .number-input {
  width: 80% !important;
  border: none !important;
  display: inline !important;
  border-radius: 0 !important;
}

.change-number-section .flag-dropdown {
  width: 20px !important;
  border: none !important;
  background-color: transparent !important;
}

.change-number-section .country-Display {
  display: inline !important;
  border: 0 !important;
  width: 4.5rem !important;
  display: inline !important;
  border-radius: 0 !important;
}

.change-number-section .react-tel-input {
  z-index: 1 !important;
  width: 3rem !important;
}

.dashboard-header-bg {
  /*admin topbar background color*/
  background-color: #ffffff !important;
}

html {
  overflow-y: overlay;
}

.copy-icon {
  float: right;
  display: inline;
}

.fa-eye {
  color: #617392;
}

.fa-copy {
  color: #617392;
}

.notify-bg li:hover {
  background-color: #f9fbff;
  border-radius: 7px;
}

.hd-bg-clr {
  background-color: #ebf1fd !important;
}

.ntf-clr {
  background-color: #a1afc7 !important;
  color: white !important;
}

.all-notify-drop .dropdown-menu ul li:last-child {
  margin-bottom: 0px;
}

.no-notif {
  margin-bottom: 0px;
}

.img-upload-error {
  /* Admin Logo Upload error message*/
  color: #fb0000 !important;
}

.graph-buttons {
  display: flex !important;
}

.ant-space {
  margin-right: 8px !important;
}

.cusotm-modal.change-number-modal .modal-body {
  min-height: auto !important;
}

.w-m-y-otr {
  height: 39px !important;
}

.graph-buttons .ant-space-horizontal .ant-space-item {
  height: 100% !important;
}

.graph-buttons .ant-space-horizontal .ant-space-item .ant-picker {
  height: 39px !important;
  border-radius: 5px !important;
}

.filter-modal {
  padding: 0 !important;
}

.more-alerts {
  text-align: right;
  margin-bottom: 1rem;
  margin-right: 1rem;
  color: #617392;
  cursor: pointer;
}

.alerts-pricing-modal {
  padding: 15px;
  /* background-color: #f1f5fc; */
}

.alerts-li {
  font-size: 13px;
}

.alerts-btn {
  text-align: right;
  border: none;
  outline: 0;
}

.pricing-span {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}

.admin-setting {
  background-color: #e9ecef;
  opacity: 1;
  box-shadow: none;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  color: #617392;
  font: 500 15px/18px "Roboto", sans-serif;
  height: 44px;
  margin-bottom: 5px;
}

.subs-tab {
  margin-bottom: 18px;
}

.compare-btn {
  width: 135px !important;
}

.adm-wid {
  width: 100%;
}

.custom-animated-handle {
  transition: transform 0.2s;
}

.slider-with-animated-handle:hover .custom-animated-handle {
  transform: scale(1.2);
}

.slider-with-animated-handle {
  --divider-width: 5px;
  --divider-color: #ffffff;
  --default-handle-opacity: 0.3;
}

.slider-with-animated-handle > img {
  min-width: 500px !important;
}

.compare-modal .modal-dialog {
  justify-content: center !important;
}

.analysis-loss {
  color: red !important;
}

.number-fields::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.change-phone-modal::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.edit-profile-modal > div > input::placeholder {
  color: #bdc6d4 !important;
}

/* Feature page styles */

.testimonial-cover .box-full {
  width: auto !important;
  display: flex !important;
}

.slick-dots {
  display: flex !important;
}

.slider-dashboard-sample .slick-dots {
  margin-top: 15px !important;
}

.footer-menu-links > ul > li > a,
.bnr-btn {
  cursor: pointer !important;
}

.slider-inner .slick-slide.slick-active.slick-center.slick-current {
  transform: scale(1.1);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

.slick-center-feature .slick-list .slick-track .slick-active {
  top: 50% !important;
  transition-duration: 1s;
  -webkit-transform: scale(1.1) !important;
  -ms-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
  -webkit-transform-origin: center center !important;
  -ms-transform-origin: center center !important;
  transform-origin: center center !important;
}

.slick-center-feature .slick-list .slick-track .slick-slide {
  margin-top: 60px !important;
  padding: 15px !important;
}

.slick-center-feature .slick-list .slick-track .slick-slide > div {
  margin: 0 10px !important;
}

.feature-slider-inner {
  z-index: 1 !important;
}

@media (max-width: 1679px) {
  .slick-center-feature .slick-list .slick-track .slick-slide {
    margin-top: 40px !important;
  }
}

@media (max-width: 1023px) {
  .slick-center-feature .slick-list .slick-track .slick-slide {
    margin-top: 25px !important;
  }
}

@media (max-width: 767px) {
  .date-picker-outer {
    flex-grow: 1 !important;
    display: flex !important;
    justify-content: flex-end !important;
    flex-wrap: wrap !important;
  }
}

@media (max-width: 479px) {
  .date-picker-outer .ant-space {
    margin-right: 0 !important;
  }
}

.product-list .list_items > ul {
  display: contents !important;
}

.card-left-align {
  text-align: left !important;
}

/* Compare Modal responsive  */

.compare-modal .modal-dialog {
  max-width: 790px !important;
}

.compare-modal .modal-dialog .modal-content {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.compare-modal .modal-header {
  padding: 20px 0 !important;
  margin-bottom: 10px !important;
}

@media (max-width: 1679px) {
  .compare-modal .modal-dialog {
    max-width: 550px !important;
  }
}

/* Changes modal alert description */

.alert-description {
  padding: 20px !important;
  border: solid 1px #e8f0ff !important;
}

.alert-description > div {
  border: solid 1px #e8f0ff !important;
  padding: 10px !important;
}

.alert-description h4 {
  color: #617392 !important;
  font: 700 14px/17px "Roboto", sans-serif !important;
  margin-bottom: 5px !important;
}

/* Changes modal overflow scroll */

.view-changes-modal .modal-dialog,
.view-changes-modal .modal-content {
  max-height: 93vh !important;
}

.view-changes-modal .modal-body {
  overflow-y: scroll !important;
}

.view-changes-modal ::-webkit-scrollbar {
  width: 11px !important;
}

.view-changes-modal ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #666 !important;
  border-radius: 10px !important;
}

.view-changes-modal ::-webkit-scrollbar-thumb {
  background: #697a99 !important;
  border: 2px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
  border-radius: 9999px;
}

/* --------------------------- */

.edit-profile {
  max-height: 65vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.seller-email-icon {
  width: 35px !important;
}

.adm-logo {
  height: 80px !important;
}

/* subscription modal size reduction */

.choose-subs-top {
  padding-top: 0px !important;
}

.choose-subs-bottom {
  padding-bottom: 0px !important;
}

.pricing-card-custom {
  height: 90% !important;
}

.card-details-block-custom {
  padding-bottom: 1px !important;
}

.ul-custom {
  padding-top: 5px !important;
}

.pricing-col-custom {
  min-height: 400px !important;
}

.alerts-otr ul li i {
  flex-shrink: 0 !important;
}

.head-sort-custom {
  border-bottom: 2px solid #1cc6d3 !important;
  width: max-content !important;
}

.graph-title {
  margin-bottom: 10px !important;
}

.table-min-height {
  height: auto !important;
}

.performing-nodata {
  font: 500 12px/14px "Roboto", sans-serif !important;
  color: #617392 !important;
}

.subscribedWarning {
  display: contents !important;
}

.dashboard-header .dropdown-menu > ul {
  margin-bottom: 0px !important;
}

.subscription-warning {
  margin-bottom: 0px !important;
}

.warning-notification {
  display: contents !important;
}

.pagination-otr .dropdown .dropdown-toggle::after {
  vertical-align: 0.1em !important;
}

.table-main .new-table-style tr td:first-child {
  border-bottom-right-radius: 0px !important;
}

.table-main .new-table-style tr td:last-child {
  border-bottom-left-radius: 0px !important;
}

/* .table-header-fixed th {
  z-index: 0 !important;
} */

.notify-bg {
  border: 2px solid #e8f0ff !important;
  max-height: 500px !important;
}

.review-message {
  text-align: left;
  font: 500 13px "Roboto", sans-serif;
  color: #617392;
  padding: 5px;
  /* border: 2px solid #e8f0ff;
  border-radius: 5px; */
}

.features-protect {
  -webkit-text-fill-color: white !important;
}

.features-para {
  color: white !important;
}

.table tbody td .requested {
  background-color: #5fff95;
  padding: 3px 11px;
  border-radius: 14px;
  color: #1b1e30;
  font-size: 13px;
  min-width: 82px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.metrics-daterange {
  margin-bottom: 0 !important;
  color: #617392;
  font: 600 14px "Roboto", sans-serif;
}

.prev-new-value {
  display: block;
  overflow: auto;
  border-bottom: none !important;
}

.custom-view-changes {
  padding-left: 0.7em !important;
}

.psn-static {
  position: static !important;
}
@media (max-width: 479px) {
  .right-sctn {
    justify-content: flex-end !important;
  }
}
.fontweight-bolder {
  font-weight: bolder !important;
}

.list_items-otr .list_items ul li .otr .info-otr small > div {
  /*Top Performing products revenue & unit sold align*/
  flex: 1 !important;
  width: 10rem !important;
}

/* Compare modal image enlargement */
.compare-image-cover {
  z-index: 6 !important;
}
.lightbox {
  z-index: 9999;
}
.compare-image > img {
  cursor: zoom-in !important;
}
/* ------------------------------------- */

.welcome-page .video-ftr {
  justify-content: center !important;
}
.edit-profile .react-tel-input .form-control {
  width: inherit !important;
}
.cusotm-modal.view-changes-modal
  .img-block-cover
  .img-block-wrapper
  .img-block
  .scrnshot-block {
  min-height: auto !important;
}
.choose-region-custom {
  color: #f35358;
}
.all-notify-drop .dropdown-menu ul li:last-child p {
  margin: 0 0 0.7em;
}
.notification-decription {
  font: 500 15px/20px "Roboto", sans-serif !important;
  margin-bottom: 0.3em !important;
}
.notif-time {
  font: 400 13px/20px "Roboto", sans-serif !important;
}
.already_read {
  background-color: #dbdbdb !important;
}
/* Home Page video */
/* .welcomepage-video > video{
  border: 10px solid #66c4cb;
} */

.video_box_welcome {
  width: 100%;
  background-size: contain;
  position: relative;
  padding: 0px 9px;
}
.video_box_welcome:before {
  content: "";
  position: absolute;
  width: 130px;
  height: 8px;
  border-radius: 50px;
  background: #36dce9;
  z-index: 1;
  top: 11px;
  left: 30px;
}
@media (max-width: 1199px) {
  .video_box_welcome:before {
    width: 90px;
  }
}
.video_box_welcome:after {
  content: "" "" "";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  letter-spacing: 10px;
  color: #fff;
  font-size: 9px;
  top: 11px;
  position: absolute;
  right: 20px;
}
.video_box_welcome video {
  border-radius: 23px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 20px solid #1cc6d3;
  border-top-width: 30px;
  position: relative;
}
@media (max-width: 1199px) {
  .video_box_welcome video {
    border: 10px solid #1cc6d3;
    border-top-width: 30px;
  }
}
.video-settings > video {
  height: auto !important;
}
.sidebar .notification > i {
  padding: 2px !important;
}
.deals-datepicker {
  /* width: calc(100% - 10px) !important; */
  width: 100% !important;
}
#text-box-deals {
  height: auto !important;
}
.forms-add-deal .error-border {
  border: 1.5px solid #f42718 !important;
}

.alert-warning-toaster {
  font-size: 13px;
  padding: 9px;
  color: #664d03;
  background-color: #f7f440d8;
  border-color: #ffecb5;
}
.alert-warning-toaster .alert-link {
  color: #523e02;
}

.table-actions {
  min-width: 24px !important;
}

.sellerFilter {
  width: 250px !important;
}

.sellerFilter .seach-prod-item .spd-asin {
  padding-left: 0px !important;
}
.export-buttons {
  cursor: pointer !important;
}
.export-buttons a {
  color: black !important;
}
.ant-picker-dropdown {
  z-index: 1060 !important;
}
.sellerFilter > div {
  z-index: 100;
}
.select-product > div {
  z-index: 101;
}
.ptr-none {
  pointer-events: none;
}
#blogModal .modal-title {
  font-size: 20px;
  font-weight: bold;
}
.currency-selection > div > div > div {
  /* border: 1px solid #E6EAF1; */
  color: #617392 !important;
}
.currency-selection > div {
  border: 1px solid #e6eaf1 !important;
}

/* .dealmgmt-select {
  z-index: 0 !important;
} */

.ovr-hdn {
  overflow: hidden !important;
}

.disable-link {
  color: #9ca4b1;
}

.notfound-wap > h3 {
  color: rgba(0, 0, 0, 0.85) !important;
}

.coupon-code-input {
  padding-right: 0.5 !important;
  padding-left: 0.5 !important;
}
/* price cr */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sugg-info .form-control:disabled {
  background-color: #f5f5f5 !important;
  border: 1px solid #e6eaf1 !important;
}
.marginleft-0 {
  margin-left: 0 !important;
}

/* Temporary for test */
.flex-container {
  display: flex;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
}

.selectedplanActive {
  background-color: steelblue !important;
  color: aliceblue !important;
  border: 2px solid blue;
}

.capitalize {
  text-transform: capitalize;
}
.visible-hdn {
  visibility: hidden !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.blog-update .change-file {
  background-color: #dde0e3 !important;
  border: 1px solid #ced4da !important;
  color: #212529 !important;
  cursor: pointer !important;
}

::-webkit-file-upload-button {
  cursor: pointer;
}
input[type="file"] {
  cursor: pointer;
}
.character_count_left {
  float: right !important;
  color: rgb(143, 143, 143) !important;
}

.upgrade-warning {
  font-size: 13px !important;
}

@media screen and (max-width: 1679px) {
  .choose-subscription .card .card-body .no-creditcard {
    font-size: 22px !important;
    line-height: 24px !important;
  }
}

/* ---------------------------------------- */
.custom-container-lg {
  max-width: 1300px;
}
@media (max-width: 1679px) {
  .custom-container-lg {
    max-width: 1170px;
  }
  .pricing-sction .pricing-card-otr {
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    grid-template-columns: repeat(4, calc(23.5% - 0px)) !important;
  }
  .pricing-sction .pricing-card-otr .pricing-card .list-style {
    padding-right: 10px;
  }
}

@media (max-width: 1199px) {
  .custom-container-lg {
    max-width: 85%;
  }
  .pricing-sction .pricing-card-otr {
    grid-template-columns: repeat(2, calc(50% - 0px)) !important;
  }
}
@media (max-width: 767px) {
  .pricing-sction .pricing-card-otr {
    grid-template-columns: repeat(1, calc(100% - 0px)) !important;
  }
}
/* React quill Styles */
.ql-align-right {
  text-align: right !important;
}
.ql-align-center {
  text-align: center !important;
}
.ql-align-left {
  text-align: left !important;
}
.ql-indent-1 {
  padding-left: 30px !important;
}
.ql-indent-2 {
  padding-left: 45px !important;
}
.ql-indent-3 {
  padding-left: 60px !important;
}
.ql-indent-4 {
  padding-left: 75px !important;
}
.ql-indent-5 {
  padding-left: 90px !important;
}
.ql-indent-6 {
  padding-left: 105px !important;
}
.ql-indent-7 {
  padding-left: 120px !important;
}
.ql-indent-8 {
  padding-left: 135px !important;
}
.forms-blog-add .ql-editor {
  /* height: 80px !important; */
  overflow: auto;
  padding: 8px 15px !important;
}
.title-editor .ql-editor {
  height: 78px !important;
}
.title-no-editor .ql-editor {
  height: 115px !important;
}
.text-box .ql-editor {
  height: 200px !important;
}
.videodesc-no-editor .ql-editor {
  height: 170px !important;
}
.videodesc-editor .ql-editor {
  height: 120px !important;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 8px !important;
}
.ql-formats > button {
  height: 22px !important;
}
/* sub list  */
.ql-indent-1,
.ql-indent-2,
.ql-indent-3,
.ql-indent-4,
.ql-indent-5,
.ql-indent-6,
.ql-indent-7,
.ql-indent-8 {
  list-style-type: initial;
  list-style-position: inside;
}
.ql-indent-1:before,
.ql-indent-2:before,
.ql-indent-3:before,
.ql-indent-4:before,
.ql-indent-5:before,
.ql-indent-6:before,
.ql-indent-7:before,
.ql-indent-8:before {
  display: none !important;
}
.blog-data .content .content-area ul li {
  margin-bottom: 8px !important;
  line-height: 1.5 !important;
}
.blog-data .content .content-area ul li::before {
  top: 0.4em !important;
}
.blog-data .text h1,
.blog-data .text h2,
.blog-data .text h3,
.blog-data .text h4,
.blog-data .text h5,
.blog-data .text h6 {
  margin-bottom: 0.5em !important;
  margin-top: 0.7em !important;
}
.blog-data .content .content-area {
  padding-top: 1px !important;
}
.blog-data .blog-description {
  padding-bottom: 5px !important;
  margin-bottom: 2px !important;
}
.blog-data .content .content-area ul {
  margin-bottom: 15px !important;
}
.blog-data .text p {
  text-align: left;
  letter-spacing: 0px;
  color: #4f6d8f;
  font-size: 15.5px;
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 5px;
}

.no-editor .ql-container.ql-snow {
  border-top: 1px solid #ccc !important;
}
.no-editor .ql-toolbar.ql-snow {
  display: none !important;
}
.ql-align-justify {
  text-align: justify !important;
}

.pricing-card .head h3 {
  color: #fff !important;
}

.pricing-card .head h3.free {
  background-color: #aab4c3;
  border: 1px solid #aab4c3;
}

.pricing-card .head h3.basic {
  background-color: #91a0b8;
  border: 1px solid #91a0b8;
}

.pricing-card .head h3.plus {
  background-color: #617392;
  border: 1px solid #617392;
}

.pricing-card .head h3.premium {
  background-color: #3f4b61;
  border: 1px solid #3f4b61;
}

#header_data {
  font-family: monospace !important;
}
.ql-editor > p {
  color: #424242 !important;
}

#header_data::placeholder {
  color: rgb(168, 168, 168) !important;
}
.fba-modal {
  z-index: 99999 !important;
  background-color: white !important;
}

.positive {
  color: #51a535 !important;
}
.negative {
  color: #d62a24 !important;
}

.pricing-card {
  cursor: pointer;
}

.pricing-card .head h3,
.pricing-card .days {
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
}

.pricing-card .head h3:hover {
  opacity: 0.8;
}

.pricing-card .days:hover {
  background: rgba(1, 163, 255, 0.2) !important;
}
.pricing-sction .pricing-card-otr .pricing-card {
  transition: all ease-in-out 0.4s;
}
.pricing-sction .pricing-card-otr .pricing-card:hover {
  box-shadow: 0 3px 33px rgba(154, 188, 209, 0.5);
  transform: translateY(-5px);
}
.more-link {
  color: #1cc6d3;
  transition: all ease-in-out 0.2s;
}
.more-link:hover {
  color: #01a3ff;
}
.fba-modal {
  max-width: 1268px !important;
}
.txt1 {
  padding-left: 5px;
}
.psn-static .form-switch {
  width: 100px;
}

@media (min-width: 1200px) {
  .gradient-header {
    background-color: transparent !important;
    background: transparent !important;
  }
}

.modal-style {
  display: block !important;
}
.fba-sales-border {
  border-color: #7c01d9 !important;
}
.fba-fetch-inp button:disabled {
  background-color: #c2c2c2 !important;
  border: 1px solid #c2c2c2 !important;
}
.fba-fetch-inp .search-otr {
  width: 350px !important;
}
.slick-track .slick-slide {
  overflow: hidden;
}
/* .slick-track{
  float: right !important;
} */

.css-8mmkcg {
  display: none !important;
}

.contLoad {
  opacity: 0.7;
  transform: scale(0.5) translate(-20px, -7px);
  margin-left: -18px;
}

.contLoad .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 60px;
}
.contLoad .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #1cc6d3;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.contLoad .lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
  animation: lds-ellipsis1 0.6s infinite;
}
.contLoad .lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}
.contLoad .lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}
.contLoad .lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
  animation: lds-ellipsis3 0.6s infinite;
}
.contLoad{
  height: 10px;
  margin-bottom: 23px;
}
.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 0.5em;
  margin-left: 11em;
  display: block;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}
span.menu-icon {
  flex: 0 0 18px;
}
span.menutext {
  white-space: nowrap;
}
.no-underline{
  text-decoration: none !important;
}
.generate-script-btn:disabled {
  background-color: #1cc6d359 !important;
  cursor: not-allowed !important;
}
#aiTools_submenu li{
  margin-bottom: 0 !important;
}

.asin-table .table-header-fixed th{
  position: static;
}
.demoVideo{
  width: 100vw !important;
  aspect-ratio: 1.77777778;
  max-width: 1200px !important;
  object-fit: contain;
  height: unset !important;
}
.status {
  padding: 10px 17px;
  font: 500 14px/27px Roboto;
  border-radius: 23px;
  text-align: center;
  min-width: 88px;
  height: 32px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.status.light-grey {
  background: rgba(97, 115, 146, 0.1607843137);
  color: #617392;
}
.status.grey {
  background: #d4dff1;
  color: #3B70CB;
}
.status.light-green {
  background: #e0f1eb;
  color: #1CC6D3;
}
.status.light-red {
  background: rgba(255, 78, 0, 0.0784313725);
  color: #FF4E00;
}
@media screen and (max-width: 1240px){
  .demoVideo{
    max-width: calc(100vw - 40px) !important;
  }
}
.table-header-fixed th{
  z-index: 1 !important;
}
.dashboard-header{
  z-index: 2 !important;
 }
 /* -----------AI VIDEO PAYMENT ---------------- */
 .sm-center-modal .content-modal-inner .icon-info.icon-info{
  margin-bottom: 20px;
}
.sm-center-modal .content-modal-inner .icon-info.icon-info img{
  width: 80px;
}
.makePay{
  font-size: 14px;
  font-weight: 600;
  color: #617392;
  
}
.makePay .amt{
  color: #06c1cf;
}
.add-spy-btn:disabled {
  background-color: #9de8ee !important;
}
/* --------------------------------------- */
.css-26l3qy-menu {
  min-width: 128px;
}
.css-26l3qy-menu .css-4ljt47-MenuList {
  font-size: 12px;
}

.isDisabled {
  position: relative;
}
.isDisabled:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 2;
  cursor: not-allowed;
  backdrop-filter: opacity(0.4);
}
.isDisabled .input-item {
  opacity: 0.7;
}

.asin-inputs:focus{box-shadow:none !important}
.asin-inputs.is-invalid{border: none;}
.input-item.error-border{
  border: 1.5px solid #f42718 !important;
}
.modal-asin .asin-inputs.is-invalid{
  padding-right: 28px ;
}
.asin-table .table-header-fixed th{
  position: static;
}
.keyword-form button:disabled {
  opacity: .4 !important;
}
.create-video-info-box{
  background-color: rgba(28, 198, 211, 0.0509803922) !important;
  background: rgba(28, 198, 211, 0.0509803922);
  border: 1px solid rgba(28, 198, 211, 0.3019607843);
  border-radius: 9px;
  display: flex;
}
.create-video-info-box p{
  color: #0FA8B4;
}
.modal-asin .custom-sel__indicator{
  width: 36px;
  height: 36px;
}
.competitor-graph{
  padding-bottom: 15px !important;
  padding: 40px 15px;
  margin-bottom: 30px;
  border: 1px solid #e8f0ff  !important;
  background-color: #f9fbff;
  border-radius: 5px;
  max-height: 360px;
  transition: all ease-in-out 0.5s;
}

.competitor-graph.hidden{
  max-height: 0px !important;
  overflow: hidden !important;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid transparent !important;
  background-color: #ffffff;
}

.competitor-graph.hidden > div{
  opacity: 0;
}
.refer-confirm-modal h3{
  font: normal normal bold 26px/32px Roboto !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.refer-confirm-modal p, button{
  font-family: Arial, Helvetica, sans-serif !important;
}

.features-paraa{
  color: white !important;
  padding-left: 15px;
  font-family: 'Poppins', sans-serif;
}

.sm-center-modal .content-modal-inner h3 {
  font-family: "Roboto", sans-serif !important;

}
.recommended .blog-item .blog-img img{aspect-ratio: 1.5;-o-object-position:top;object-position:top;height: unset !important;}
/* refer table filter class */
.btn-ref-filter{
  min-width: 146px !important;
}

.ref-status{
  font-weight: 300;
  color: #a1afc7;
   
}
.dashboard-header .right-sctn .notify .count{
  width:unset !important;
  min-width: 22px;
  padding-left: 2px;
  padding-right: 2px;
}
.dashboard-header .right-sctn .notify .count span{
  font-size: 9px !important;
}
.footer-newsletter-wrap .form-control::placeholder{
  color: #acb6cf !important;
  font-size: 16px !important;
}

.choose-subscription .planSelector input[type=radio]{
  cursor: default !important;
}
.btn-bck{
  color: #1CC6D3 !important;
}
.main{
  max-height: 100vh;
  overflow: auto;
}
.freePlan .main_title {
  padding-bottom: 0;
  margin-bottom: 0;
}
.freePlan .choose-subscription .card .card-body {
  padding-top: 15px;
}
@media screen and (max-width: 1441px) {
  .freePlan .chooseP-text-content {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 1441px) {
  .freePlan .chooseP-text-content .btn-group {
    padding-top: 10px !important;
  }
}
/* for suggest deal valid to */
.ant-picker-input input{
  opacity: 1 !important;
  z-index: 1 !important;
  position: static !important;
}

.txt-primary{ 
  color: #0d6efd !important;
  font-weight: bold;
}